.title-container {
  text-align: center;
  margin: 20px 0;
}

.title {
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.dash {
  width: 350px;
  height: 1px;
  background-color: #817d7d; /* Grey color */
  margin: 0 auto;
  margin-bottom: 15px;
}

.subtitle {
  text-align: center;
  color: #817d7d;
  font-size: 19px;
  margin-bottom: 60px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 50px;
  justify-content: center;
  padding-top: 20px;
}

.image-wrapper {
  position: relative; /* Relative positioning for overlay effect */
  overflow: hidden;
  border-radius: 8px;
}

.image-grid img {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

/* Hover effect */
.image-wrapper:hover img {
  transform: scale(1.05); /* Slight zoom on hover */
  opacity: 0.5; /* Dim the image */
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(18, 14, 14);
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  opacity: 0;
  transition: opacity 0.5s ease; /* Fade-in effect */
  pointer-events: none; /* Prevents interfering with hover */
}

/* Show text on hover */
.image-wrapper:hover .hover-text {
  opacity: 1; /* Changed to 1 for visibility */
}

.image-wrapper:hover {
  cursor: pointer; /* Adds pointer cursor on hover */
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for large tablets */
  }
}

@media (max-width: 992px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small tablets */
    gap: 30px; /* Reduced gap */
  }
  .title {
    font-size: 1.4rem; /* Slightly smaller font size */
  }
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: 1fr; /* 1 column for mobile devices */
    gap: 20px; /* Reduced gap */
  }
  .title {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }
  .subtitle {
    font-size: 16px; /* Smaller subtitle for mobile */
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 1.1rem; /* Even smaller font size */
  }
  .subtitle {
    font-size: 14px; /* Smaller subtitle */
  }
  .hover-text {
    font-size: 1.5rem; /* Adjust hover text size */
  }
}
