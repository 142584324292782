.text-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 1200px; /* Adjust based on your design */
  padding: 50px;
  box-sizing: border-box;
}

.text-item {
  flex: 1;
  padding: 20px;
  background-color: white; /* Example background color */
  border-radius: 20px;
  box-shadow: 0 8px px rgba(0, 0, 0, 0.1);
  line-height: 2;
}

.divider {
  width: 2px;
  background-color: #ddd; /* Color of the divider line */
  height: auto; /* Makes the divider line fill the height of the text items */
  margin: 0 100px; /* Space between text items and divider */
}
/* Styling for the text items */
.text-item-one h2 {
  font-family: "Raleway", sans-serif;
  font-size: 70px;
  font-style: italic;
  text-align: center;
  text-decoration: wavy;
  text-shadow: 5ch;
  color: dimgrey;
}

.text-item-one h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 33px;
  color: dimgrey;
  font-family: "Raleway", sans-serif;
  text-shadow: 5ch;
  text-decoration: wavy;
  font-style: italic;
  margin-left: 5%;
}

/* Card container with even space for cards */
.card-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px auto;
  max-width: 1000px; /* Limits the overall width */
  flex-wrap: wrap; /* Allows cards to wrap if screen size is small */
}

/* Individual card styling */
.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex-basis: calc(
    33.333% - 20px
  ); /* Ensures 3 cards per row with even space */
  margin-bottom: 35px;
}

/* Card image styling with fixed dimensions */
.card-image {
  width: 100%;
  height: 200px; /* Fix height for even spacing */
  object-fit: cover; /* Ensures image fits well within the fixed height */
}

/* Card title styling */
.card-title {
  margin: 0;
  padding: 10px;
  background: #f5f5f5;
  font-size: 1.2rem;
}

/* Hover effect for the card */
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.our-service {
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

/* Container for the kitchen section */
.kitchen-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* For overlay positioning */
  margin-bottom: 0; /* Adjust as needed */
}

/* Style for the full-width image */
.full-width-image {
  width: 100%;
  height: 300px; /* Adjust height as needed */
  object-fit: cover;
  margin-bottom: 0; /* Remove margin below the image */
  margin-top: 80px;
}
.our-service {
  margin-top: 40px;
}

/* Overlay style for darkening image */
.kitchen-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

/* Heading style within the overlay */
.kitchen-overlay h1 {
  font-size: 36px;
  margin: 0;
  font-weight: bold;
}

/* Horizontal underline below the heading */
.kitchen-underline {
  width: 50px;
  height: 2px;
  background-color: rgb(255, 255, 255); /* Light color for the underline */
  margin-top: 10px;
  border: none;
}
