/* Container for the entire layout */
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
}
h1 {
  text-align: center;
  font-size: 40px;
}

/* Image Section */
.image-section {
  text-align: center;
  margin-bottom: 30px;
}

.interior-image {
  width: 700px;
  height: 350px;
  border-radius: 0px 70px;
}

/* Text Section */
.text-section {
  font-size: 18px;
  line-height: 1.8;
  text-align: justify;
}

.text-section p {
  margin-bottom: 20px;
}

/* Heading in Text Section */
.text-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}
span {
  color: rgb(43, 166, 243);
  font-weight: bold;
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .interior-image {
    width: 100%;
    max-width: 100%;
  }

  .text-section {
    font-size: 16px;
    line-height: 1.6;
  }
}
