/* General reset for margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}

.contact-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically by default */
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Limit max-width for larger screens */
  margin: auto; /* Center container horizontally */
}

.contact-info,
.contact-form {
  width: 100%;
  margin-bottom: 20px; /* Space between sections */
}

/* Ensure each section takes up 100% width on smaller screens */
.contact-info {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-info h2,
.contact-form h2 {
  font-size: 2rem; /* Responsive font size */
  text-align: center;
  margin-bottom: 10px;
}

.underline {
  width: 50px;
  height: 2px;
  background-color: #0e34f0de; /* Blue underline */
  margin: 10px auto; /* Center and add margin */
}

/* Style for individual info items */
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.contact-info h4 {
  font-size: 1.2rem; /* Responsive font size */
}

.contact-info p {
  margin: 5px 0;
  font-size: 1rem; /* Responsive font size */
}

/* Form styles */
form {
  display: flex;
  flex-direction: column; /* Stack form elements vertically */
}

.form-item {
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem; /* Responsive font size */
}

textarea {
  resize: none;
}

button[type="submit"] {
  background-color: #04438c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem; /* Responsive font size */
  margin-top: 20px;
  align-self: flex-start; /* Align button to the start */
}

button[type="submit"]:hover {
  background-color: #0052a3;
}

/* Responsive styles */
@media (min-width: 768px) {
  .contact-container {
    flex-direction: row; /* Align sections side by side on larger screens */
    justify-content: space-between;
    padding: 40px;
  }

  .contact-info,
  .contact-form {
    width: 48%; /* Slightly less than 50% to provide some space between */
  }

  .contact-info {
    margin-right: 2%; /* Space between sections */
  }

  .contact-form {
    margin-left: 2%; /* Space between sections */
  }

  .contact-info h2,
  .contact-form h2 {
    font-size: 2.5rem; /* Larger font size for larger screens */
  }

  .form-item input[type="text"],
  .form-item input[type="email"],
  .form-item textarea {
    font-size: 1.1rem; /* Slightly larger font size for larger screens */
  }

  button[type="submit"] {
    font-size: 1.1rem; /* Slightly larger font size for larger screens */
  }
}

@media (max-width: 767px) {
  .contact-info,
  .contact-form {
    width: 100%;
  }

  .contact-container {
    padding: 20px;
  }
}
