/* General container styles */
.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.name {
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold;
  margin-bottom: 20px; /* Space between the text and the line/dash */
  position: relative;
  text-align: center;
}

.name::after {
  content: "";
  display: block;
  width: 35%;
  height: 1px; /* Adjust the thickness of the line */
  background: #817d7d; /* Adjust the color of the line */
  position: absolute;
  bottom: -10px; /* Adjust the distance of the line from the text */
  left: 50%; /* Center the line */
  transform: translateX(-50%); /* Center the line */
}

.client {
  text-align: center;
  color: #817d7d;
  font-size: larger;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px; /* Add padding to avoid content touching edges */
  box-sizing: border-box;
  margin-bottom: 30px;
}

.buttons button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 10px; /* Add padding to avoid content touching edges */
  box-sizing: border-box;
}

.section {
  flex: 1 1 100%;
  margin: 10px;
  padding: 20px;
  border: 1px solid #e3dfdf;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.image-text-container {
  display: flex;
  align-items: flex-start;
}

.image {
  width: 400px; /* Set the width as needed */
  height: 400px; /* Set the height as needed */
  object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  margin-right: 50px;
  margin-left: 20px; /* Space between the image and text */
}

.image-text-container ul {
  list-style-type: disc; /* Bullet points for unordered list */
  margin: 0; /* Remove default margin */
  padding-left: 20px; /* Add padding for bullet points */
}

.image-text-container li {
  margin-bottom: 12px; /* Space between list items; increased for better readability */
  line-height: 2; /* Improve line spacing within list items */
}

.buttons button.active {
  background-color: #4608e1; /* Button background for active state */
  color: white; /* Button text color for active state */
  border: none; /* Remove default border for active button */
}

.section {
  flex: 1 1 100%;
  margin: 10px;
  padding: 20px;
  border: 1px solid #e3dfdf;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for background and border color */
}

/* Responsive styles */

/* For small screens like mobile phones */
@media only screen and (max-width: 600px) {
  .name {
    font-size: 18px;
  }

  .name::after {
    width: 50%;
  }

  .client {
    font-size: medium;
  }

  .image {
    width: 100%;
    height: auto; /* Let the image height adjust with its width */
    margin-right: 0;
    margin-left: 0;
  }

  .buttons {
    padding: 0;
  }

  .section {
    padding: 15px;
  }

  .image-text-container {
    flex-direction: column;
    align-items: center;
  }

  .image-text-container ul {
    padding-left: 10px;
  }
}

/* For tablets and smaller desktops */
@media only screen and (max-width: 1024px) {
  .image {
    width: 300px;
    height: 300px;
  }

  .name {
    font-size: 20px;
  }

  .buttons {
    justify-content: space-between;
  }

  .section {
    padding: 20px;
  }
}
