/* Banner styles */
.luxury-banner {
  background-image: url("./images/interior-background.jpg");
  background-size: cover;
  background-position: center;
  height: 300px; /* Default height */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Overlay with slight dark effect */
.luxury-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Heading style */
.luxury-overlay h1 {
  font-size: 36px; /* Default font size */
  margin: 0;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 40px;
}

/* Horizontal underline below the text */
.luxury-underline {
  width: 50px;
  height: 2px;
  background-color: #00aeef; /* Blue underline */
  margin-top: 10px;
  border: none;
  justify-content: start;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .luxury-banner {
    height: 250px; /* Adjust height for medium screens */
  }

  .luxury-overlay h1 {
    font-size: 32px; /* Slightly smaller heading for medium screens */
    padding-top: 40px;
  }
}

@media (max-width: 992px) {
  .luxury-banner {
    height: 200px; /* Adjust height for tablets */
  }

  .luxury-overlay h1 {
    font-size: 28px; /* Adjust font size for tablets */
    padding-top: 40px;
  }

  .luxury-underline {
    width: 40px; /* Smaller underline for tablets */
  }
}

@media (max-width: 768px) {
  .luxury-banner {
    height: 180px; /* Adjust height for mobile devices */
  }

  .luxury-overlay h1 {
    font-size: 24px; /* Adjust font size for mobile devices */
    padding-top: 100px;
  }

  .luxury-underline {
    width: 30px; /* Smaller underline for mobile devices */
  }
}

@media (max-width: 576px) {
  .luxury-banner {
    height: 150px; /* Further adjust height for very small screens */
  }

  .luxury-overlay h1 {
    font-size: 20px; /* Smallest font size for very small screens */
    padding-top: 100px;
  }

  .luxury-underline {
    width: 25px; /* Smallest underline for very small screens */
  }
}
