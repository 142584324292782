/* Container for the entire layout */
.c-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Arial", sans-serif;
    color: #333;
  }
  h1 {
    text-align: center;
    font-size: 40px;
  }
  
  /* Image Section */
  .c-image-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .c-interior-image {
    width: 700px;
    height: 350px;
    border-radius: 0px 70px;
  }
  
  /* Text Section */
  .c-text-section {
    font-size: 18px;
    line-height: 1.8;
    text-align: justify;
  }
  
  .c-text-section p {
    margin-bottom: 20px;
  }
  
  /* Heading in Text Section */
  .c-text-section h1 {
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
  }
  span {
    color: rgb(43, 166, 243);
    font-weight: bold;
  }
  
  /* Media Query for responsiveness */
  @media (max-width: 768px) {
    .c-container {
      padding: 10px;
    }
  
    .c-interior-image {
      width: 100%;
      max-width: 100%;
    }
  
    .c-text-section {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  
