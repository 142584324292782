.project-banner {
   background-image: url('./images/refer.jpg');
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    height: 300px; /* Set the height of the banner */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 80px;
  }
  
  /* Overlay with slight dark effect */
  .project-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    color: white;
    text-align: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Contact heading style */
  .project-overlay h1 {
    font-size: 36px;
    margin: 0;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  /* Horizontal underline below the text */
  .project-underline {
    width: 50px;
    height: 2px;
    background-color: #00aeef; /* Blue underline */
    margin-top: 10px;
    border: none;
  }
  