#address {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
  }
  .responsive-map {
    overflow: hidden;
    height: 0;
    padding-bottom: 30%;
    position: relative;
  }
  .responsive-map iframe {
    border: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
 /* Center the text inside the parent div */
.center-div {
    text-align: center;
    font-family:Arial;
}

/* Optional: Remove any default margins on the h4 if needed */
.center-div h4 {
    margin: 0; /* Remove default margin if needed */
    margin-bottom: 20px;
}
