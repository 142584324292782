.full-width-image-con {
  width: 80%;
  height: 300px; /* Make height auto for better responsiveness */
  object-fit: cover;
  margin: 0 auto; /* Center the image */
  margin-top: 80px;
}

.con-content {
  text-align: center;
  margin-top: 80px;
  font-family: "Lobster", cursive;
}

.con-container {
  display: flex;
  align-items: center; /* Align image and text vertically */
  justify-content: center;
  gap: 30px; /* Space between image and text */
  padding: 20px;
  margin-bottom: 20px;
}

/* Media query for tablets and larger phones */
@media (max-width: 1024px) {
  .con-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .img-con {
    margin: 0; /* Remove margins for better alignment */
  }

  .con-para {
    margin-top: 20px; /* Adjust margin for spacing */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .con-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .img-con {
    margin: 0; /* Remove margins for better alignment */
  }

  .con-para {
    margin-top: 20px; /* Adjust margin for spacing */
    padding: 20px; /* Less padding on smaller screens */
    font-size: 1rem; /* Smaller font size for mobile */
  }
}

/* Styles for very small devices (e.g., small smartphones) */
@media (max-width: 480px) {
  .full-width-image-con {
    width: 90%; /* Make image width more flexible */
    height: 300px;
  }

  .con-para {
    font-size: 0.9rem; /* Smaller font size */
    padding: 10px; /* Less padding */
  }
}

.image-con {
  flex: 0 0 300px; /* Adjust width for responsiveness */
  max-height: 400px; /* Limit height */
}

.img-con {
  width: 100%; /* Image takes full width of the container */
  height: auto; /* Image height adjusts based on aspect ratio */
  object-fit: cover; /* Ensure the image fills the container without distortion */
  border-radius: 10px; /* Optional: Rounded corners */
}

.con-para {
  flex: 1; /* Text container takes remaining space */
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.8; /* Line height for better readability */
  max-width: 600px; /* Set max width for text */
  font-size: 1.2rem; /* Adjust font size */
  text-align: left; /* Align text to left */
  margin-top: 40px;
}
