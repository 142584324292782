.full-width-image-ren {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-top: 80px;
}

.ren-content {
  text-align: center;
  margin-top: 60px;
  font-family: "Lobster", cursive;
}

.ren-container {
  display: flex;
  justify-content: space-between; /* Ensure even space between elements */
  align-items: center; /* Aligns image and text vertically */
  gap: 70px;
  padding: 50px;
}

/* Media query for tablets and larger phones */
@media (max-width: 1024px) {
  .ren-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    padding: 20px; /* Less padding for smaller screens */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ren-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .img-ren {
    height: 250px; /* Adjust height for mobile */
    margin-bottom: 20px; /* Space between image and paragraph */
  }

  .ren-para {
    font-size: 1rem; /* Smaller font size for mobile */
    line-height: 1.5; /* Adjust line height for readability */
    margin-bottom: 40px; /* Less margin */
  }
}

/* Styles for very small devices (e.g., small smartphones) */
@media (max-width: 480px) {
  .full-width-image-ren {
    height: 300px; /* Smaller image height */
  }

  .ren-para {
    font-size: 0.9rem; /* Smaller font size */
    padding: 10px; /* Less padding */
  }
}

.image-container {
  flex: 1; /* Allow image container to grow */
}

.img-ren {
  width: 100%; /* Ensure the image takes full width of its container */
  height: auto; /* Adjust height based on aspect ratio */
  object-fit: cover; /* Cover the container without distortion */
}

.ren-para-container {
  flex: 1; /* This makes the text container take up equal space */
}

.ren-para {
  line-height: 2.3;
  font-size: 1.2em;
  margin-bottom: 110px; /* Adjust as needed */
}

.divider {
  width: 2px;
  background-color: #ddd;
  height: 100%;
  margin: 0 20px;
}
