/* Container for the entire section */
.interior-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Header section */
.header-banner {
  background-color: #dcdcdc;
  border-left: 8px solid #04438c;
  border-bottom: 5px solid #04438c;
  width: 90%;
  padding: 10px 20px;
  margin-bottom: 20px;
  text-align: left;
}
.header h1 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
}

/* Content section - Contains both image and text */
.content-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Image section styling */
.image-section {
  width: 50%;
  padding: 10px;
}

.interior-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Text section styling */
.text-section {
  width: 45%;
  padding: 10px;
}

.text-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

/* Responsive Design for mobile view */
@media screen and (max-width: 768px) {
  .content-section {
    flex-direction: column;
    align-items: center;
  }

  .image-section,
  .text-section {
    width: 100%;
    text-align: center;
  }

  .header-banner h1 {
    font-size: 1.5rem;
  }

  .text-section p {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 480px) {
  .header-banner h1 {
    font-size: 1.2rem;
  }

  .text-section p {
    font-size: 0.9rem;
  }

  .read-more-btn {
    padding: 10px 20px;
  }
}

/* Base Styles */
/* Base Styles */
.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #000;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-content {
  width: 55%;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.text-content p {
  margin-bottom: 20px;
}

.image-container {
  width: 40%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive Design for tablets (max-width: 768px) */
@media screen and (max-width: 768px) {
  .content-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .image-container {
    order: 1; /* Ensure image comes first */
    width: 100%; /* Make the image take full width */
    margin-bottom: 20px; /* Adjust margin */
  }

  .text-content {
    order: 2; /* Ensure text comes second */
    width: 100%; /* Make the text take full width */
    text-align: center;
  }

  .title {
    font-size: 1.5rem; /* Adjust title size for tablet view */
  }

  .text-content p {
    font-size: 0.95rem; /* Adjust paragraph size */
  }
}

/* Responsive Design for mobile devices (max-width: 480px) */
@media screen and (max-width: 480px) {
  .title {
    font-size: 1.2rem; /* Smaller title size for mobile */
  }

  .image-container img {
    border-radius: 5px; /* Adjust image corner rounding */
  }

  .text-content p {
    font-size: 0.9rem; /* Smaller paragraph font size */
  }

  .image-container,
  .text-content {
    width: 100%; /* Full width on mobile */
    text-align: center;
  }
}
