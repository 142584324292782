@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0;
}

.full-width-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 0;
    margin-top: 10px;
}

.about-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 380px;
    background-color: rgba(70, 68, 68, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.about-overlay h1 {
    font-size: 36px;
    margin: 0;
    font-weight: bold;
}

.about-underline {
    width: 50px;
    height: 2px;
    background-color: hsla(229, 100%, 96%, 0.871);
    margin-top: 10px;
    border: none;
}

.h3-content {
    margin-top: 30px;
    font-size: 50px;
    color: #333;
    font-family: 'Great Vibes', cursive;
    margin-bottom: 20px;
    text-align: center;
}

.about-paragraph {
    margin-bottom: 50px;
    line-height: 2.9;
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
    .full-width-image {
        height: 250px;
    }

    .about-overlay h1 {
        font-size: 32px;
    }

    .h3-content {
        font-size: 40px;
    }

    .about-paragraph {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 768px) {
    .full-width-image {
        height: 200px;
    }

    .about-overlay h1 {
        font-size: 28px;
    }

    .h3-content {
        font-size: 35px;
    }

    .about-paragraph {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 480px) {
    .full-width-image {
        height: 150px;
    }

    .about-overlay h1 {
        font-size: 24px;
    }

    .h3-content {
        font-size: 28px;
    }

    .about-paragraph {
        padding-left: 10px;
        padding-right: 10px;
        line-height: 2;
    }
}
