/* Container for the entire layout */
.lu-container {
  max-width: 1200px; /* Adjusted for larger screens */
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Heading */
h1 {
  text-align: center;
  font-size: 40px;
}

/* Image Section */
.lu-image-section {
  text-align: center;
  margin-bottom: 30px;
}

.lu-interior-image {
  width: 100%;
  max-width: 700px;
  height: auto; /* Maintain aspect ratio */
  border-radius: 0px 70px;
}

/* Text Section */
.lu-text-section {
  font-size: 18px;
  line-height: 1.8;
  text-align: justify;
}

.lu-text-section p {
  margin-bottom: 20px;
}

/* Highlighted text */
span {
  color: rgb(43, 166, 243);
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 992px) {
  .lu-container {
    padding: 15px;
  }

  h1 {
    font-size: 36px; /* Slightly smaller heading for medium screens */
  }

  .lu-interior-image {
    border-radius: 0px 50px; /* Adjust border radius for medium screens */
  }
}

@media (max-width: 768px) {
  .lu-container {
    padding: 10px;
  }

  h1 {
    font-size: 32px; /* Adjust heading size for tablets */
  }

  .lu-interior-image {
    border-radius: 0px 30px; /* Adjust border radius for tablets */
  }

  .lu-text-section {
    font-size: 16px;
    line-height: 1.6;
  }
}

@media (max-width: 576px) {
  .lu-container {
    padding: 5px;
  }

  h1 {
    font-size: 28px; /* Smaller heading size for mobile screens */
  }

  .lu-interior-image {
    border-radius: 0px 20px; /* Adjust border radius for mobile screens */
  }

  .lu-text-section {
    font-size: 14px;
    line-height: 1.5;
  }
}
