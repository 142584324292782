.full-width-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-top: 80px;
}

.h3-content {
  text-align: center;
  margin-top: 60px;
  font-family: "Lobster", cursive;
}

.about-content-container {
  display: flex;
  justify-content: space-between; /* Ensures equal space between the image and text */
  align-items: center; /* Aligns image and text vertically */
  gap: 20px;
  padding: 50px;
}

/* Media query for tablets and larger phones */
@media (max-width: 1024px) {
  .about-content-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    padding: 20px; /* Less padding for smaller screens */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .about-content-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    gap: 10px; /* Adjust gap for better spacing */
  }

  .img-about-int {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between image and paragraph */
  }

  .about-paragraph-int {
    font-size: 1rem; /* Smaller font size for mobile */
    line-height: 1.5; /* Adjust line height for readability */
    margin-bottom: 40px; /* Less margin */
    padding: 0 10px; /* Add padding for better readability */
  }
}

/* Styles for very small devices (e.g., small smartphones) */
@media (max-width: 480px) {
  .full-width-image {
    height: 200px; /* Smaller image height */
  }

  .about-paragraph-int {
    font-size: 0.9rem; /* Smaller font size */
    padding: 0 5px; /* Less padding */
  }
}

.image-container {
  flex: 1; /* Allows the image container to take equal space */
}

.img-about-int {
  width: 400px; /* Make the image take the full width of the container */
  height: auto; /* Ensure the image height adjusts */
  object-fit: cover;
  margin-bottom: 30px;
}

.about-text-container {
  flex: 1; /* Allows the text container to take equal space */
}

.about-paragraph-int {
  line-height: 2.1;
  font-size: 1.2em;
  text-align: justify;
  margin-bottom: 200px; /* Adjust as needed */
}

.highlight-name {
  font-weight: bold;
  color: #007bff; /* Adjust the color as needed */
}
