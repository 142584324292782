/* Container for the card grid */
.card-container {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 1fr)
    ); /* 3 columns of 300px minimum width */
    gap: 20px; /* Space between the cards */
    padding: 20px;
  }
  
  /* Each card style */
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Hover effect on cards */
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Image inside each card */
  .card-image {
    width: 100%;
    height: 270px;
    object-fit: cover;
  }
  
  /* Title of the card */
  .card-title {
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  /* Responsive design */
  @media screen and (max-width: 768px) {
    .card-container {
      grid-template-columns: repeat(
        auto-fill,
        minmax(200px, 1fr)
      ); /* Adjust to 2 columns on smaller screens */
    }
  
    .card-image {
      height: 150px;
    }
  }
  