/* Container for the choice section */
.choice-container {
  width: 100%;
  box-sizing: border-box;
}

/* Banner styling */
.choice-banner {
  background-image: url("./images/interior-background.jpg"); /* Path to the image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  height: 300px; /* Set the height of the banner */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Overlay with dark effect */
.choice-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Heading styling */
.choice-overlay h1 {
  font-size: 2.2rem; /* Responsive font size */
  margin: 0;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.2; /* Line height for better readability */
  padding-top: 40px;
}

/* Horizontal underline below the text */
.choice-underline {
  width: 50px;
  height: 2px;
  background-color: #00aeef; /* Blue underline */
  margin-top: 10px;
  border: none;
}

/* Styles for Cwords container */
.cwords-container {
  padding: 20px;
}

/* Responsive styles */
@media (min-width: 768px) {
  .choice-overlay h1 {
    font-size: 1.6rem; /* Increase font size for larger screens */
    padding-top: 40px;
  }

  .choice-underline {
    width: 100px; /* Increase width of the underline for larger screens */
  }
}

@media (max-width: 767px) {
  .choice-overlay h1 {
    font-size: 1.2rem; /* Decrease font size for smaller screens */
    padding: 40px; /* Adjust padding for smaller screens */
  }

  .choice-underline {
    width: 40px; /* Decrease width of the underline for smaller screens */
  }
}
