.full-width-image-arc {
  width: 50%;
  height: 300px; /* Adjust height as needed */
  object-fit: cover;
  margin-bottom: 0;
  margin-top: 80px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.img-archi {
  max-height: 150%;
  max-width: 150%;
  object-fit: cover;
  margin-top: 70px;
  margin-left: 20px;
}

.archi-content {
  text-align: center;
  margin-top: 60px;
  font-family: "Lobster", cursive;
}

.content-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 1200px;
  padding: 50px;
  box-sizing: border-box;
  margin-left: 20px;
  gap: 20px;
}

.divider {
  width: 2px;
  background-color: #ddd;
  height: 500px;
  margin: 0 100px;
}

.arc-para {
  flex: 1;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 8px px rgba(0, 0, 0, 0.1);
  line-height: 2.5;
}

/* Responsive Design */

/* Extra Small Devices (e.g., iPhone SE, older Android phones) */
@media (max-width: 320px) {
  .content-container {
    flex-direction: column-reverse; /* Ensure paragraph is below the image */
    padding: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 10px 0;
  }

  .image-container {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .img-archi {
    margin-left: 0;
    margin-top: 10px;
    max-width: 100%;
  }

  .arc-para {
    padding: 10px;
    font-size: 0.9em;
  }

  .archi-content {
    font-size: 1.1em;
  }
}

/* Small Devices (e.g., iPhone 6/7/8, smaller Android phones) */
@media (max-width: 375px) {
  .content-container {
    flex-direction: column-reverse; /* Ensure paragraph is below the image */
    padding: 15px;
  }

  .divider {
    width: 100%;
    height: 2px;
    margin: 15px 0;
  }

  .image-container {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .img-archi {
    margin-left: 0;
    margin-top: 15px;
    max-width: 100%;
  }

  .arc-para {
    padding: 15px;
    font-size: 1em;
  }

  .archi-content {
    font-size: 1.2em;
  }
}

/* Medium Devices (e.g., iPhone 11/12/13, mid-range Android phones) */
@media (max-width: 414px) {
  .content-container {
    flex-direction: column-reverse; /* Ensure paragraph is below the image */
    padding: 20px;
  }

  .divider {
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }

  .image-container {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .img-archi {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .arc-para {
    padding: 20px;
    font-size: 1.1em;
  }

  .archi-content {
    font-size: 1.3em;
  }
}

/* Large Devices (e.g., iPhone Plus models, larger Android phones) */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column-reverse; /* Ensure paragraph is below the image */
    padding: 30px;
  }

  .divider {
    width: 100%;
    height: 3px;
    margin: 25px 0;
  }

  .image-container {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
  }

  .img-archi {
    margin-left: 0;
    margin-top: 25px;
    max-width: 100%;
  }

  .arc-para {
    padding: 25px;
    font-size: 1.2em;
  }
}
