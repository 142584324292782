.stats-section {
  padding: 20px 0;
  width: 100%;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  background-image: url("C:/Users/T460/Downloads/VPR Construction/VPR Construction/src/images/bg-5.jpg"); /* Replace with correct path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.stat-card {
  flex: 1;
  padding: 10px;
  max-width: 250px;
  margin: 10px;
}

.stat-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #333;
}

h2 {
  margin: 5px 0;
  font-size: 1.8rem;
  color: #000;
}

p {
  font-size: 1.1rem;
  color: #1e0606;
}

/* Responsive Design for Small, Medium, Large and Extra-Large screens */

/* Extra Small Devices (Portrait phones, less than 480px) */
@media (max-width: 480px) {
  .stats-container {
    flex-direction: column; /* Stack the cards vertically */
    padding: 10px;
  }

  .stat-card {
    max-width: 100%; /* Allow cards to take up full width */
    padding: 10px 5px;
  }

  .stat-icon {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}

/* Small Devices (Tablets and small screens, 481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .stats-container {
    flex-direction: row;
    flex-wrap: wrap; /* Wrap items if there are too many */
  }

  .stat-card {
    max-width: 200px; /* Narrower cards on small screens */
  }

  .stat-icon {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.05rem;
  }
}

/* Medium Devices (Tablets, 769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .stats-container {
    justify-content: space-between;
    padding: 20px;
  }

  .stat-card {
    max-width: 220px;
  }

  .stat-icon {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  p {
    font-size: 1.1rem;
  }
}

/* Large Devices (Desktops, 1025px and larger) */
@media (min-width: 1025px) {
  .stats-container {
    justify-content: space-around;
  }

  .stat-card {
    max-width: 250px;
  }

  .stat-icon {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.1rem;
  }
}

/* Extra Large Devices (Desktops, 1440px and larger) */
@media (min-width: 1440px) {
  .stats-container {
    justify-content: space-evenly;
  }

  .stat-card {
    max-width: 300px;
  }

  .stat-icon {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }
}
