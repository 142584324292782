/* Basic Styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: whitesmoke;
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 90px;
  z-index: 1000;
}

body {
  padding-top: 100px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 18rem;
  height: auto;
  margin-right: 10px;
}

/* Navigation Styles */
.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  text-decoration: none;
  font-size: 1rem;
  color: #090a0b;
  font-weight: bold;
}

.nav-item a.active-link {
  border-bottom: 2px solid #0765c9;
}

.nav-item a:hover {
  color: #0765c9;
}

/* Dropdown Menu */
.nav-item .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 160px;
  z-index: 1000;
  padding: 10px 0;
  list-style: none;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 8px 12px;
}

.dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #f8f8f8;
  color: #007bff;
}

/* Phone Button */
.phone-button {
  display: flex;
  align-items: center;
  background-color: #04438c;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.phone-button:hover {
  background-color: #0765c9;
}

.phone-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.phone-number {
  font-family: Arial, sans-serif;
}

.phone-number a {
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: whitesmoke;
    position: absolute;
    top: 90px;
    left: 0;
    z-index: 1000;
  }

  .nav.active {
    display: flex;
  }

  .nav-list {
    display: block;
    width: 100%;
  }

  .nav-item {
    margin: 10px 0;
  }

  .phone-icon-container {
    display: none; /* Hide phone icon container on small screens by default */
  }

  .phone-icon-container.active {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    right: 20px; /* Align to the right side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust vertical position */
  }

  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
  }
  .logo-image {
    width: 15rem;
  }
}

@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }

  .phone-icon-container {
    display: flex;
  }
}
