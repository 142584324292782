/* Base Styles for Banner */
.banner {
  background-image: url("C:\Users\T460\Downloads\VPR Construction\VPR Construction\src\images\banner-bg.jpg"); /* Path to the image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  height: 300px; /* Set the height of the banner */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Overlay with slight dark effect */
.overlay1 {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Contact heading style */
.overlay1 h1 {
  font-size: 36px;
  margin: 0;
  font-weight: bold;
}

/* Horizontal underline below the text */
.underline {
  width: 50px;
  height: 2px;
  background-color: #04438c; /* Blue underline */
  margin-top: 10px;
  border: none;
}

/* Responsive Design for Tablets (max-width: 768px) */
@media screen and (max-width: 768px) {
  .banner {
    height: 250px; /* Reduce banner height */
  }

  .overlay1 {
    padding: 17px; /* Adjust padding */
  }

  .overlay1 h1 {
    font-size: 28px; /* Reduce font size for heading */
  }

  .underline {
    width: 40px; /* Adjust underline size */
  }
}

/* Responsive Design for Mobile Devices (max-width: 480px) */
@media screen and (max-width: 480px) {
  .banner {
    height: 200px; /* Further reduce banner height for mobile */
  }

  .overlay1 {
    padding: 14px; /* Further reduce padding */
  }

  .overlay1 h1 {
    font-size: 24px; /* Smaller font size for mobile */
  }

  .underline {
    width: 30px; /* Make the underline narrower */
  }
}
