/* General styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
}

.testimonials-page {
  text-align: center;
  color: white;
  padding: 50px;
  background-image: url("./images/img1.png"); /* Background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Optional: to make the background image stay in place while scrolling */
}

.testimonials-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow testimonials to wrap on smaller screens */
}

.testimonial {
  background: rgba(0, 0, 0, 0.126); /* Slight transparency */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: left;
  position: relative;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px; /* Make testimonials responsive */
}

.quote {
  font-size: 0.5rem;
  margin-bottom: 20px;
}

.testimonials-page p {
  font-size: 0.8rem; /* Adjust as needed */
  color: #e0e0e0; /* Change to the desired color */
}

.author {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-info h4 {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #0973c9; /* Optional: customize text color */
}

.author-info p {
  font-size: 0.8rem;
  color: #ebdede;
}

/* Responsive styles */

/* For screens between 300px and 500px */
@media only screen and (max-width: 500px) {
  .testimonials-page {
    padding: 20px;
  }

  .testimonials-container {
    gap: 20px; /* Reduced gap for smaller screens */
  }

  .testimonial {
    max-width: 100%; /* Allow testimonials to take full width */
    padding: 15px;
    flex: 1 1 100%; /* Ensure testimonials stack vertically */
  }

  .quote {
    font-size: 0.4rem;
  }

  .testimonials-page p {
    font-size: 0.7rem;
  }

  .author-info h4,
  .author-info p {
    font-size: 0.7rem;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }
}

/* For screens between 300px and 400px */
@media only screen and (max-width: 400px) {
  .quote {
    font-size: 0.35rem;
  }

  .testimonials-page p {
    font-size: 0.65rem;
  }

  .author-info h4,
  .author-info p {
    font-size: 0.65rem;
  }

  .avatar {
    width: 35px;
    height: 35px;
  }
}

/* For screens less than 300px (if needed) */
@media only screen and (max-width: 300px) {
  .testimonials-page {
    padding: 10px;
  }

  .quote {
    font-size: 0.3rem;
  }

  .testimonials-page p {
    font-size: 0.6rem;
  }

  .author-info h4,
  .author-info p {
    font-size: 0.6rem;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }
}
