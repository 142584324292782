/* Style for the interior banner */
.interior-banner {
  background-image: url("./images/interior-background.jpg");
  background-size: cover;
  background-position: center;
  height: 300px; /* Default height for larger screens */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.interior-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interior-overlay h1 {
  font-size: 36px; /* Default font size for larger screens */
  margin: 0;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 40px;
}

.interior-underline {
  width: 50px;
  height: 2px;
  background-color: #00aeef; /* Blue underline */
  margin-top: 10px;
  border: none;
  justify-content: start;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .interior-banner {
    height: 250px; /* Adjust height for medium screens */
  }

  .interior-overlay h1 {
    font-size: 32px; /* Slightly smaller font size */
    padding-top: 40px;
  }
}

@media (max-width: 992px) {
  .interior-banner {
    height: 200px; /* Adjust height for tablets */
  }

  .interior-overlay h1 {
    font-size: 28px; /* Adjust font size for tablets */
    padding-top: auto;
  }

  .interior-underline {
    width: 40px; /* Smaller underline for tablets */
  }
}

@media (max-width: 768px) {
  .interior-banner {
    height: 180px; /* Adjust height for mobile devices */
  }

  .interior-overlay h1 {
    font-size: 24px; /* Adjust font size for mobile devices */
    padding-top: 100px;
  }

  .interior-underline {
    width: 30px; /* Smaller underline for mobile devices */
  }
}

@media (max-width: 576px) {
  .interior-banner {
    height: 150px; /* Further adjust height for very small screens */
  }

  .interior-overlay h1 {
    font-size: 20px; /* Smallest font size for very small screens */
    padding-top: 100px;
  }

  .interior-underline {
    width: 25px; /* Smallest underline for very small screens */
  }
}
