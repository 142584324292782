/* Style for the banner container */
.contact-banner {
  background-image: url("/src/images/banner.jpg"); /* Path to the image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  height: 300px; /* Set the height of the banner */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 70px;
}

/* Overlay with slight dark effect */
.contact-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Contact heading style */
.contact-overlay h1 {
  font-size: 2rem; /* Responsive font size */
  margin: 0;
  font-weight: bold;
}

/* Horizontal underline below the text */
.contact-underline {
  width: 50px;
  height: 2px;
  background-color: #0e34f0de; /* Blue underline */
  margin-top: 10px;
  border: none;
}

/* Responsive styles */
@media (min-width: 768px) {
  .contact-overlay h1 {
    font-size: 2.5rem; /* Increase font size for larger screens */
  }

  .contact-underline {
    width: 70px; /* Increase width of the underline for larger screens */
  }
}

@media (max-width: 767px) {
  .contact-banner {
    height: 200px; /* Decrease height of the banner for smaller screens */
    margin-top: 50px; /* Adjust margin for smaller screens */
  }

  .contact-overlay h1 {
    font-size: 1.5rem; /* Decrease font size for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .contact-underline {
    width: 40px; /* Decrease width of the underline for smaller screens */
  }
}
