.footer-container {
  background-color: #e0e0e0; /* Light grey background */
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Max width for larger screens */
  flex-wrap: wrap; /* Wrap the content for smaller screens */
  margin-bottom: 20px;
}

.footer-address,
.footer-contact,
.footer-locations {
  flex: 1;
  margin: 10px;
}

.footer-address p,
.footer-contact p,
.footer-locations p {
  margin: 5px 0;
}

.footer-locations p {
  display: flex;
  align-items: center;
}

.footer-bottom {
  width: 100%;
  background-color: #192d94; /* Blue background */
  padding: 10px;
  text-align: center;
  color: #e0e0e0;
}
.footer-bottom p {
  color: #e0e0e0;
}
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-address,
  .footer-contact,
  .footer-locations {
    text-align: center;
  }
}
