.about-page {
  padding: 20px;
}

.about-container {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.full-width-image {
  width: 100%;
  height: 300px; /* Adjust the height for larger screens */
  object-fit: cover;
}

.about-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.about-underline {
  width: 50px;
  height: 4px;
  background-color: white;
  margin: 10px auto 0;
}

.h3-content {
  text-align: center;
  margin: 40px 0;
  font-family: "Lobster", cursive;
}

.about-content-container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.image-container {
  flex: 1;
}

.img-about {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 150px;
}

.about-text-container {
  flex: 2;
  padding: 20px;
}

.about-paragraph {
  font-size: 16px;
  line-height: 2.5;
}

.highlight-name {
  font-weight: bold;
  color: #2c3e50;
}

/* Responsive Design */

/* Tablets and larger screens */
@media (max-width: 768px) {
  .about-content-container {
    flex-direction: column; /* Stack the image and text vertically */
    gap: 20px; /* Reduce gap between image and paragraph */
  }

  .img-about {
    height: 280px; /* Adjust image height for tablets */
  }

  .about-paragraph {
    font-size: 15px; /* Slightly reduce font size for readability */
    margin-bottom: 20px; /* Add some space after the paragraph */
    line-height: 1.5;
  }

  .full-width-image {
    height: 240px;
  }

  .about-underline {
    width: 40px;
  }

  .h3-content {
    padding-bottom: 40px; /* Adjusted margin for better spacing */
  }
}

/* Mobile screens */
@media (max-width: 480px) {
  .about-page {
    padding: 10px;
  }

  .about-content-container {
    flex-direction: column-reverse; /* Ensure the paragraph is after the image */
    gap: 10px; /* Reduce the gap between the image and text */
  }

  .img-about {
    height: 180px; /* Reduce image height on mobile */
    margin-bottom: 30px; /* Reduced margin */
  }

  .about-paragraph {
    font-size: 1em; /* Adjust text size for mobile */
    margin-bottom: 15px; /* Reduce space after the paragraph */
  }

  .full-width-image {
    height: 300px; /* Reduce height for header image */
  }

  .about-underline {
    width: 30px;
  }

  .h3-content {
    margin-top: 25%; /* Adjusted margin for mobile */
    font-size: 20px;
  }
}
