/* General styles */
.blog-container {
  text-align: center;
  margin: 40px 20px;
  margin-top: -50px;
  margin-bottom: 60px;
}

.blog-container h2 {
  font-size: 2rem;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 80px;
}

.blog-container h2::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 50px;
  height: 3px;
  background-color: #03519b;
}

.blog-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.blog-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  max-width: 300px;
  flex-grow: 1;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Equal height for all boxes */
  transition: transform 0.3s ease;
  border-radius: 0px 0px 0px 40px;
}

.blog-card h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.blog-card p {
  font-size: 1rem;
  color: #666;
  text-align: justify;
  margin-bottom: 20px;
  flex-grow: 1; /* Ensures text expands within the card */
}

.blog-card button {
  background-color: #03519b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto; /* Centers the button */
  transition: background-color 0.3s ease;
}

.blog-card button:hover {
  background-color: #0044cc;
}

.blog-card:hover {
  transform: translateY(-5px);
}

span {
  font-weight: bold;
}

/* Responsive styles */

/* For screens up to 1024px (tablets and smaller desktops) */
@media only screen and (max-width: 1024px) {
  .blog-container {
    margin: 20px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .blog-container h2 {
    font-size: 1.8rem;
    margin-top: 60px;
  }

  .blog-grid {
    gap: 15px;
  }

  .blog-card {
    max-width: 280px;
  }

  .blog-card h3 {
    font-size: 1.1rem;
  }

  .blog-card p {
    font-size: 0.9rem;
  }

  .blog-card button {
    padding: 8px 16px;
  }
}

/* For screens up to 768px (tablets) */
@media only screen and (max-width: 768px) {
  .blog-container {
    margin: 10px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .blog-container h2 {
    font-size: 1.6rem;
    margin-top: 40px;
  }

  .blog-grid {
    gap: 10px;
  }

  .blog-card {
    max-width: 100%; /* Cards take full width */
  }

  .blog-card h3 {
    font-size: 1rem;
  }

  .blog-card p {
    font-size: 0.85rem;
  }

  .blog-card button {
    padding: 8px 14px;
  }
}

/* For screens up to 480px (mobile phones) */
@media only screen and (max-width: 480px) {
  .blog-container {
    margin: 5px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .blog-container h2 {
    font-size: 1.4rem;
    margin-top: 20px;
  }

  .blog-grid {
    gap: 5px;
  }

  .blog-card {
    max-width: 100%; /* Cards take full width */
    padding: 15px;
  }

  .blog-card h3 {
    font-size: 0.9rem;
  }

  .blog-card p {
    font-size: 0.8rem;
  }

  .blog-card button {
    padding: 6px 12px;
  }
}
